//src/contexts/GlobalContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import { auth, db } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, setDoc, updateDoc, onSnapshot } from 'firebase/firestore';

const GlobalContext = createContext();

export const useGlobalContext = () => useContext(GlobalContext);

export const GlobalProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [onboardingStatus, setOnboardingStatus] = useState(0); // Directly track onboarding status
  const [activePage, setActivePage] = useState('settings');

  // Handles user state changes.
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (authUser) => {
      setUser(authUser); // Update user state.
      if (authUser) {
        // If user is signed in, check and update the onboarding status from Firestore.
        await checkOnboardingStatus(authUser.uid);
      } else {
        // If user is signed out, reset the onboarding status.
        setOnboardingStatus(0);
      }
    });
    return () => unsubscribe(); // Cleanup subscription on unmount.
  }, []);

  // Subscribes to real-time updates of the user's onboarding status.
  useEffect(() => {
    // Subscribes to real-time updates of the user's onboarding status.
    if (user && user.uid) {
      const userRef = doc(db, 'Users', user.uid);
      const unsubscribe = onSnapshot(userRef, (doc) => {
        if (doc.exists() && doc.data().onboardingStatus !== undefined) {
          // Update local onboarding status with the value from Firestore.
          setOnboardingStatus(doc.data().onboardingStatus);
        }
      });
      return () => unsubscribe(); // Ensure we unsubscribe when the component unmounts or user changes.
    }
  }, [user]); // Rerun when user state changes to ensure we're always listening to the correct document.

  // Checks and updates the onboarding status from Firestore once.
  const checkOnboardingStatus = async (uid) => {
    const docRef = doc(db, 'Users', uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      if (docSnap.data().onboardingStatus !== undefined) {
        setOnboardingStatus(docSnap.data().onboardingStatus);
      } else {
        // Initialize onboardingStatus for new users
        setOnboardingStatus(1); // Start of onboarding process
        await updateDoc(docRef, { onboardingStatus: 1 }); // Update Firestore to reflect this
      }
    } else {
      // Handle case where user document does not exist
      // Potentially create a new document for the user with initial settings
      console.error("User document does not exist. Initializing...");
      setOnboardingStatus(1); // Set initial onboarding status
      await setDoc(docRef, { onboardingStatus: 1 }); // Initialize user document with onboardingStatus
    }
  };

  // Updates the onboarding status in Firestore and locally.
  const updateOnboardingStatus = async (newStatus) => {
    if (user && user.uid) {
      const userRef = doc(db, 'Users', user.uid);
      await updateDoc(userRef, { onboardingStatus: newStatus });
      setOnboardingStatus(newStatus); // Update local state to reflect the new status immediately.
    }
  };

  return (
    <GlobalContext.Provider value={{
      user,
      onboardingStatus,
      updateOnboardingStatus,
      activePage,
      setActivePage
    }}>
      {children}
    </GlobalContext.Provider>
  );
};