// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { GlobalProvider, useGlobalContext } from './contexts/GlobalContext';
import ProtectedRoute from './components/ProtectedRoute';
import Onboarding from './pages/Onboarding'; // Import the Onboarding component

import HomePage from './pages/HomePage';
import SignUpPage from './pages/SignUpPage';
import LoginPage from './pages/LoginPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import TermsOfServicePage from './pages/TermsOfServicePage';
import CallsPage from './pages/CallsPage';
import SettingsPage from './pages/SettingsPage';
import NotFoundPage from './pages/NotFoundPage';
import DemoPage from './pages/DemoPage';
import { ToastContainer } from 'react-toastify'; // Import ToastContainer
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify CSS

function App() {
  return (
    <GlobalProvider>
      <Router>
        <ToastContainer />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/privacy" element={<PrivacyPolicyPage />} />
          <Route path="/terms" element={<TermsOfServicePage />} />
          <Route path="/onboarding" element={<OnboardingWrapper />} />
          <Route path="/demo/:uniqueKey" element={<DemoPage />} />
          <Route
            path="/calls"
            element={
              <ProtectedRoute>
                <CallsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <ProtectedRoute>
                <SettingsPage />
              </ProtectedRoute>
            }
          />
          <Route path="/" element={<Navigate replace to="/signup" />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </GlobalProvider>
  );
}

// DDD
// Wrapper component to use the context and decide redirection
function OnboardingWrapper() {
  const { onboardingStatus } = useGlobalContext();

  // If onboardingStatus is 5, redirect to settings as onboarding is complete
  if (onboardingStatus === 5) {
    return <Navigate to="/settings" />;
  }

  // Otherwise, stay in the onboarding flow
  return <Onboarding />;
}
// DDD^^^

export default App;
