// src/pages/Onboarding.js
import React, { useEffect } from 'react';
import { useGlobalContext } from '../contexts/GlobalContext';
import UserSettings from '../components/onboarding/UserSettings';
import AssistantSettings from '../components/onboarding/AssistantSettings';
import AssistantPhoneNumber from '../components/onboarding/AssistantPhoneNumber';
import CallForwarding from '../components/onboarding/CallForwarding';

// import SettingsPage from './SettingsPage';

const Onboarding = () => {
  const { onboardingStatus, updateOnboardingStatus } = useGlobalContext();

  // Handle the Stripe Checkout success scenario
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.get('payment') === 'success' && onboardingStatus === 2) {
      // Assuming Stripe Checkout corresponds to the 'Billing' step which is now external
      // Directly setting onboarding status to the next step after payment
      updateOnboardingStatus(3); // Update to the step following Stripe Checkout

    }
  }, [updateOnboardingStatus]);

  // Determine which component to render based on onboardingStatus
  const renderStepComponent = () => {
    switch (onboardingStatus) {
      case 1: return <UserSettings />;
      case 2: return <AssistantSettings />;
      case 3: return <AssistantPhoneNumber />;
      case 4: return <CallForwarding />;
      // case 5: return <SettingsPage />;
      default: return <div>Loading...</div>;
    }
  };

  return (
    <div>
      {renderStepComponent()}
    </div>
  );
};

export default Onboarding;
