// src/components/Navbar.js
import React from 'react';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase';
import logo from '../assets/images/ringaling-logo.png';
import { useGlobalContext } from '../contexts/GlobalContext';

const Navbar = () => {
  const { setActivePage, user } = useGlobalContext();

  const handleLogout = async () => {
    try {
      await signOut(auth);
      // Optional: Redirect or change state on logout
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  return (
    <div className="flex justify-between items-center py-4 bg-white shadow-md">
      <img src={logo} alt="Ring a Ling" className="h-12" />
      <div className="flex items-center">
        <button onClick={() => setActivePage('calls')} className="mx-2 text-lg">Calls</button>
        <button onClick={() => setActivePage('settings')} className="mx-2 text-lg">Settings</button>
        <button onClick={handleLogout} className="mx-2 text-lg text-red-500 hover:text-red-700">Logout</button>
      </div>
    </div>
  );
};

export default Navbar;