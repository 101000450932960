// src/components/ProtectedRoute.js
import React, { useEffect, useState } from 'react';
import { useGlobalContext } from '../contexts/GlobalContext';
// Import your onboarding and other components
import LoginPage from '../pages/LoginPage';
import UserSettings from '../components/onboarding/UserSettings';
import AssistantSettings from '../components/onboarding/AssistantSettings';
import AssistantPhoneNumber from '../components/onboarding/AssistantPhoneNumber';
import CallForwarding from '../components/onboarding/CallForwarding';
// import App from '../App'; // Assuming 'App' is your main application component

import SettingsPage from '../pages/SettingsPage';
import CallsPage from '../pages/CallsPage';

// This function is used to determine which component to render based on onboarding status
const getOnboardingComponent = (status) => {
  switch (status) {
    case 1:
      return <UserSettings />;
    case 2:
      return <AssistantSettings />;
    case 3:
      return <AssistantPhoneNumber />;
    case 4:
      return <CallForwarding />;
    default:
      return <div>Loading...</div>; // Fallback for unknown status
  }
};

const ProtectedContent = () => {
  const { user, onboardingStatus, activePage } = useGlobalContext();

  useEffect(() => {
    // This effect does not need to explicitly set content anymore because it's
    // directly derived from the activePage state.
    // We just ensure the correct component renders based on global state.
  }, [user, onboardingStatus, activePage]); // Listening to changes in user, onboarding status, and active page

  // Determining what content to render based on user and onboarding status
  if (!user) {
    return <LoginPage />;
  } else if (onboardingStatus < 5) {
    return getOnboardingComponent(onboardingStatus);
  } else {
    // Navigate based on activePage state
    switch (activePage) {
      case 'settings':
        return <SettingsPage />;
      case 'calls':
        return <CallsPage />;
      default:
        return <div>Error: Page not found</div>; // Fallback for unknown page
    }
  }
};

export default ProtectedContent;