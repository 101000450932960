// src/pages/HomePage.js
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/ringaling-logo.png';

// Importing images
import iqImage from '../assets/images/features-IQ.png';
import uniqueImage from '../assets/images/features-unique.png';
import calendarImage from '../assets/images/features-calendar.png';
import messagesImage from '../assets/images/features-messages.png';
import phoneImage from '../assets/images/features-phone.png';
import voiceImage from '../assets/images/features-voice.png';

const HomePage = () => {
    return (
        <div className="flex flex-col min-h-screen bg-white">
            {/* Nav Bar */}
            <nav className="bg-white shadow-md py-4 px-8 flex justify-between items-center">
                <div className="flex items-center">
                    <img src={logo} alt="Ring-a-Ling Logo" className="h-8 mr-2"/>
                    <span className="font-bold text-xl">Ring-a-Ling</span>
                </div>
                <div>
                    <Link to="/login" className="text-gray-800 font-bold hover:text-gray-600 mx-4">
                        Log In
                    </Link>
                    <Link to="/signup" className="text-green-800 font-bold hover:text-green-900 mx-4">
                        Sign Up
                    </Link>
                </div>
            </nav>
            {/* Hero Section */}
            <div className="text-center py-12 max-w-4xl mx-auto"> 
                <h1 className="text-6xl text-gray-800 mb-12">An AI assistant who answers your phone out of hours and when you can't</h1>
                {/*
                <p className="text-2xl text-gray-600 mb-8">
                    75% of callers don't leave a voicemail, and 85% of callers won't call you back.
                </p>
                <p className="text-2xl text-gray-600 mb-8">
                    Missed calls = Lost opportunities!
                </p>
                */}
                <p className="text-2xl text-gray-600 mb-14">
                    Never miss another call again with your own AI assistant who can answer your phone, share information about you & your business, schedule appointments, and much more!
                </p>
                <div className="mb-12">
                    <Link to="/login">
                        <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded mx-2">
                            Log In
                        </button>
                    </Link>
                    <Link to="/signup">
                        <button className="bg-green-800 hover:bg-green-900 text-white font-bold py-2 px-4 rounded mx-2">
                            Sign Up
                        </button>
                    </Link>
                </div>
            </div>

            {/* Features Section */}
            <div className="p-4 mb-20">
                <h2 className="text-4xl text-gray-800 text-center font-bold mb-6">Your own AI assistant who...</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    <FeatureCard
                        title="Answers Your Phone"
                        description="Has the ability to answer your calls: only when you don't pick up, after hours, when you're on vacation, or all of the time."
                        image={phoneImage}
                    />
                    <FeatureCard
                        title="With a Realistic Voice"
                        description="Speaks with your callers in an engaging and conversational way that sounds almost identical to a real voice. Only 1 in 50 people can hear the difference!"
                        image={voiceImage}
                    />
                    <FeatureCard
                        title="Knows You and Your Business"
                        description="Unique and private to you with the ability to answer whatever questions you like. For example questions about: you, your business, your products/services, or anything else you'd like."
                        image={uniqueImage}
                    />
                    <FeatureCard
                        title="Schedules Appointments"
                        description="Can schedule appointments with your callers by discussing their prefered time/day, integrating with your calendar, checking your availability, and booking an appointment on your calendar."
                        image={calendarImage}
                    />
                    <FeatureCard
                        title="Takes Messages"
                        description="Efficiently takes a messages from callers, ensuring you get every detail of the call relayed back to you."
                        image={messagesImage}
                    />
                    <FeatureCard
                        title="Strong Reasoning Skills"
                        description="Has strong reasoning skills, broad general knowledge, and the ability to answer complex questions."
                        image={iqImage}
                    />
                </div>
            </div>

            {/* Footer */}
            <div className="bg-gray-100 py-4 text-center w-full">
                <Link to="/terms" className="text-gray-600 hover:text-gray-900 mx-4">
                    Terms of Service
                </Link>
                <Link to="/privacy" className="text-gray-600 hover:text-gray-900 mx-4">
                    Privacy Policy
                </Link>
            </div>
        </div>
    );
};

export default HomePage;

// FeatureCard Component
const FeatureCard = ({ title, description, image }) => (
    <div className="bg-white shadow-md rounded-lg p-4 hover:shadow-lg transition-shadow duration-300 text-center">
        <img src={image} alt={title} className="h-40 mx-auto rounded-lg"/>
        <h3 className="text-2xl font-bold my-2">{title}</h3>
        <p>{description}</p>
    </div>
);
