// src/components/onboarding/AssistantPhoneNumber.js
import React, { useState } from 'react';
import { useGlobalContext } from '../../contexts/GlobalContext';
import { toast } from 'react-toastify';
import { updateDoc, doc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions'; // Import Firebase functions
import { db } from '../../firebase';

const AssistantPhoneNumber = ({ onNext }) => {
  const { user, updateOnboardingStatus } = useGlobalContext();
  const [assistantPhoneNumber, setAssistantPhoneNumber] = useState('');
  const [loading, setLoading] = useState(false); // State to manage loading state

  const functions = getFunctions(); // Initialize Firebase Functions

  // Function to get assistant's phone number
  const getAssistantPhoneNumber = async () => {
    if (!user || !user.uid) {
      console.error('User not authenticated or UID not found.');
      toast.error("User not authenticated or UID not found.");
      return;
    }

    setLoading(true); // Set loading state to true
    const purchasePhoneNumber = httpsCallable(functions, 'purchasePhoneNumber');
    
    try {
      const result = await purchasePhoneNumber({ userId: user.uid });
      const generatedPhoneNumber = result.data.purchasedNumber; // Adjust based on your function's response

      setAssistantPhoneNumber(generatedPhoneNumber); // Set the fetched phone number in state
      setLoading(false); // Reset loading state
    } catch (error) {
      console.error('Error getting assistant phone number:', error);
      toast.error("Assistant phone number registration failed.");
      setLoading(false); // Reset loading state
    }
  };

  const validateAndProceed = async () => {
    if (!assistantPhoneNumber) {
      toast.error('Please provide an assistant phone number');
      return;
    }

    const userRef = doc(db, 'Users', user.uid);
    try {
      await updateDoc(userRef, {
        "assistantPhoneNumber": assistantPhoneNumber,
      });
      await updateOnboardingStatus(4);
    } catch (error) {
      console.error('Error updating assistant phone number:', error);
      toast.error('Failed to save assistant phone number. Please try again.');
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <div className="max-w-md mx-auto bg-white rounded-lg overflow-hidden md:max-w-lg">
        <div className="md:flex">
          <div className="w-full p-5">
            <h2 className="text-2xl font-semibold text-gray-700 text-center">Assistant Phone Number</h2>
            {loading ? (
              <div className="text-center">Please wait loading (this could take up to a minute)...</div>
            ) : (
              <>
                <div className="text-sm font-bold text-gray-700 mt-4">Assistant's Phone Number:</div>
                <input
                  type="text"
                  value={assistantPhoneNumber}
                  readOnly // Make the input read-only since the number is generated
                  className="mt-1 block w-full border-gray-200 rounded-md shadow-sm py-2 px-3 focus:border-indigo-500 focus:ring-indigo-500"
                />
                <button
                  onClick={!assistantPhoneNumber ? getAssistantPhoneNumber : validateAndProceed} // If no number, fetch one, else proceed
                  className="w-full mt-6 bg-green-800 hover:bg-green-900 text-white font-semibold py-3 px-4 rounded-md"
                >
                  {assistantPhoneNumber ? "Next" : "Get Assistant's Phone Number"}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssistantPhoneNumber;