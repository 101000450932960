// src/pages/LoginPage.js
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import logo from '../assets/images/ringaling-logo.png';
import '../assets/styles/GoogleButton.css';

const LoginPage = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleLoginWithGoogle = () => {
        const provider = new GoogleAuthProvider();
        signInWithPopup(auth, provider)
            .then(() => {
                // No need to manually set the user, as useAuthState hook in GlobalContext will automatically update the user state.
                navigate('/settings'); // Redirect to settings or appropriate page
            })
            .catch((error) => {
                setError(error.message);
            });
    };

    const handleLogin = (e) => {
        e.preventDefault();
        signInWithEmailAndPassword(auth, email, password)
            .then(() => {
                // Similarly, no need to manually set the user here.
                navigate('/settings'); // Redirect to settings or appropriate page
            })
            .catch((error) => {
                setError(error.message);
            });
    };

    return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
            <div className="bg-white p-8 rounded shadow-md text-center">
                <div className="mb-4">
                    <div className="text-4xl text-gray-800 mb-8">Ring-a-Ling</div>
                    
                    <button className="gsi-material-button" onClick={handleLoginWithGoogle}>
                        <div className="gsi-material-button-state"></div>
                        <div className="gsi-material-button-content-wrapper">
                            <div className="gsi-material-button-icon">
                                <svg version="1.1" viewBox="0 0 48 48" style={{ display: 'block' }}>
                                    <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                                    <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                                    <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                                    <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                                    <path fill="none" d="M0 0h48v48H0z"></path>
                                </svg>
                            </div>
                            <span className="gsi-material-button-contents">Sign in with Google</span>
                        </div>
                    </button>

                    <div className="my-4">OR</div>

                    <form onSubmit={handleLogin}>
                        <input
                            type="email"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker mb-4"
                            id="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <input
                            type="password"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker mb-4"
                            id="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <button
                            type="submit"
                            className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded mx-2"
                        >
                            Log In
                        </button>
                    </form>

                    {/* Error Message Display */}
                    {error && <p className="text-red-500 text-xs mt-2">{error}</p>}

                    {/* Link to Sign Up */}
                    <div className="mt-4">
                        Don't have an account?
                        <Link to="/signup" className="text-blue-500 hover:text-blue-800 ml-1">
                            Sign up here
                        </Link>
                    </div>
                    
                </div>
            </div>
        </div>
    );
};

export default LoginPage;