// src/pages/PrivacyPolicyPage.js
import React from 'react';
import { Link } from 'react-router-dom';

const PrivacyPolicyPage = () => {
    return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
            <div className="bg-white p-8 rounded shadow-md w-3/4 text-left">
                <div className="mb-4">
                    <h1 className="text-4xl text-gray-800 mb-8">Privacy Policy</h1>
                    <h2 className="text-xl font-bold text-gray-600 mb-4">Effective Date: April 23, 2024</h2>
                    <p className="text-gray-700 mb-4">
                        Welcome to Ring-a-Ling! We are committed to protecting your privacy and ensuring you have a positive experience with our AI phone answering service. This Privacy Policy outlines our practices regarding the collection, use, and protection of your information.
                    </p>

                    <h3 className="text-lg font-bold text-gray-600 mb-2">1. Data Collection</h3>
                    <p className="text-gray-700 mb-4">
                        Ring-a-Ling collects various types of data to provide and improve our AI assistant services. This includes call transcriptions, call duration, caller's phone number, calendar information, and other related data.
                    </p>

                    <h3 className="text-lg font-bold text-gray-600 mb-2">2. Purpose of Data Collection</h3>
                    <p className="text-gray-700 mb-4">
                        The data we collect is used exclusively to provide personalized AI assistant services to our customers. This may include the creation of an individualized AI model fine-tuned to your specific interactions to enhance service accuracy and user experience. Access to and use of your personalized AI model is restricted solely to you except for the third-party AI model provider used to build it. The third-party is Microsoft and they have one of the strictest privacy policies of all AI model providers: conversations are kept for 30 days and only used for abuse monitoring purposes. Microsoft doesn't hold onto your data past this timeframe and doesn't train AI models on your data or use it for any purposes other than abuse monitoring. We guarantee that your data will not be shared with third parties for marketing purposes.

                    </p>

                    <h3 className="text-lg font-bold text-gray-600 mb-2">3. Data Storage and Protection</h3>
                    <p className="text-gray-700 mb-4">
                        All data is encrypted in transit using Transport Layer Security (TLS) and at rest using AES 256-bit encryption. Users can delete individual call transcripts in the Calls page, or all call transcripts by emailing<a href="mailto:hello@ringaling.ai" className="text-blue-600 hover:text-blue-800"> hello@ringaling.ai</a>.
                    </p>

                    <h3 className="text-lg font-bold text-gray-600 mb-2">4. User Rights</h3>
                    <p className="text-gray-700 mb-4">
                        You own your call transcription data and have the right to:<br/>
                        - Access or request a copy of the data we have about you.<br/>
                        - Request corrections or deletions of your data.<br/>
                        - We will not use this data for any purposes other than to provide the services requested by you.
                    </p>

                    <h3 className="text-lg font-bold text-gray-600 mb-2">5. Consent</h3>
                    <p className="text-gray-700 mb-4">
                        By ticking the box agreeing to our terms and privacy policy during onboarding, you consent to:<br/>
                        - Our answering of your phone calls and producing transcriptions and insights to enhance your productivity.<br/>
                        - Acess to your calendar, so as your AI assistant can book appointments/meetings and give you personalized advice/help with managing your calendar.
                        - If you withdraw your consent, you will no longer be able to access our products or services. To withdraw consent, please email <a href="mailto:hello@ringaling.ai" className="text-blue-600 hover:text-blue-800"> hello@ringaling.ai</a> requesting to delete your account.
                    </p>

                    <h3 className="text-lg font-bold text-gray-600 mb-2">6. Updates to Privacy Policy</h3>
                    <p className="text-gray-700 mb-4">
                        We may update this policy in the future to reflect changes to our practices or for other operational, legal, or regulatory reasons. If changes are made, we will notify you by email or through our application.
                    </p>

                    <h3 className="text-lg font-bold text-gray-600 mb-2">7. Google APIs</h3>
                    <p className="text-gray-700 mb-4">
                        In order to work with our integrations, such as Google Calendar, we need to access your data. Ring-a-Ling's use and transfer of information received from Google APIs to any other app will adhere to <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes" target="_blank" className="text-blue-600 hover:text-blue-800">Google API Services User Data Policy</a>, including the Limited Use requirements.
                    </p>

                    <h3 className="text-lg font-bold text-gray-600 mb-2">8. User Consent for AI apps</h3>
                    <p className="text-gray-700 mb-4">
                        AI responses are generated by passing transcribed call data to a third-party AI model provider, specifically Microsoft Azure GPT 3.5. Microsoft AI models have one of the strictest <a href="https://learn.microsoft.com/en-us/legal/cognitive-services/openai/data-privacy?context=%2Fazure%2Fai-services%2Fopenai%2Fcontext%2Fcontext" target="_blank" className="text-blue-600 hover:text-blue-800">privacy policies</a> of all AI model providers: conversations are kept for 30 days and only used for abuse monitoring purposes. Microsoft doesn't hold onto your data past this timeframe and doesn't train AI models on your data or use it for any purposes other than abuse monitoring.
                    </p>


                    <h3 className="text-lg font-bold text-gray-600 mb-2">9. Contact Information</h3>
                    <p className="text-gray-700 mb-4">
                        If you have any questions or concerns about this privacy policy or our practices, please contact us at:
                        <a href="mailto:hello@ringaling.ai" className="text-blue-600 hover:text-blue-800"> hello@ringaling.ai</a>.
                    </p>
                </div>
                {/* Link back to Home or Dashboard */}
                <Link to="/" className="text-blue-500 hover:text-blue-800 mt-4 inline-block">
                    Return to Home
                </Link>
            </div>
        </div>
    );
};

export default PrivacyPolicyPage;
