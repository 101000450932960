// src/components/onboarding/AssistantSettings.js
import React, { useState, useEffect } from 'react';
import { useGlobalContext } from '../../contexts/GlobalContext';
import { toast } from 'react-toastify';
import { updateDoc, doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { getFunctions, httpsCallable } from 'firebase/functions'; // Import Firebase functions

import { updateAssistantDetails } from '../../utils/assistantUtils'; // Adjust the path as necessary
import { formatPhoneNumber } from '../../utils/formatUtils';


const AssistantSettings = ({ onNext }) => {
  const { user, updateOnboardingStatus } = useGlobalContext();
  const [assistantName, setAssistantName] = useState('');
  const [answersFor, setAnswersFor] = useState('');
  const [knowledge, setKnowledge] = useState('');
  const [demoText, setDemoText] = useState('Preview your assistant');
  const [demoTimer, setDemoTimer] = useState(null);
  const [isDemoButtonDisabled, setIsDemoButtonDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDemoLoading, setIsDemoLoading] = useState(false);

  const functions = getFunctions(); // Initialize Firebase Functions

  const validateAndProceed = async () => {

    setIsLoading(true);

    if (!assistantName || !answersFor || !knowledge) {
      setIsLoading(false);
      toast.error('Please fill in all fields');
      return;
    }
  
    // Check if user is authenticated and has a UID
    if (user && user.uid) {
      const userRef = doc(db, 'Users', user.uid);
  
      try {
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          // Check if calendarTokens map exists in the user document
          const calendarExists = userData.calendarTokens ? true : false;
  
          // Now that we have all necessary details, call the utility function
          await updateAssistantDetails(user.uid, {
            assistantName, 
            answersFor, 
            knowledge,
            companyName: userData.companyName,
            firstName: userData.firstName, 
            lastName: userData.lastName,
            recordedLineNotify: 0,
            calendarExists
          });
          await updateDoc(userRef, {"assistantPhoneNumber": "",}); // Clear the demo assistant phone number
          await handleSubmit();
        } else {
          console.error('User document does not exist');
          setIsLoading(false);
          toast.error('User details not found. Please ensure your profile is complete.');
        }
      } catch (error) {
        console.error('Error updating assistant settings:', error);
        setIsLoading(false);
        toast.error('Failed to save assistant settings. Please try again.');
      }
    } else {
      // Handle case where user is not authenticated or user.uid is undefined
      setIsLoading(false);
      toast.error('Authentication error. Please log in again.');
    }
  };

  const handleDemoClick = async () => {

    if (!assistantName || !answersFor || !knowledge) {
      toast.error('Please fill in all fields');
      return;
    }
    setIsDemoLoading(true); // Set demo button loading state to true
    const checkoutDemoPhoneNumber = httpsCallable(functions, 'checkoutDemoPhoneNumber');
    try {
      const requestData = { uid: user.uid };
      const result = await checkoutDemoPhoneNumber(requestData);
      const phoneNumber = result.data.phoneNumber;
      const phoneNumberClean = formatPhoneNumber(phoneNumber);

      // *************
      // Save the stuff to Firestore, ready for demo
      const userRef = doc(db, 'Users', user.uid);
      const userDoc = await getDoc(userRef);
      const userData = userDoc.data();
      // Check if calendarTokens map exists in the user document
      const calendarExists = userData.calendarTokens ? true : false;

      // Now that we have all necessary details, call the utility function
      await updateAssistantDetails(user.uid, {
        assistantName, 
        answersFor, 
        knowledge,
        companyName: userData.companyName,
        firstName: userData.firstName, 
        lastName: userData.lastName,
        recordedLineNotify: 0,
        calendarExists
      });
      // *************

      await updateDoc(userRef, {"assistantPhoneNumber": phoneNumber,});

      setDemoText(`Call to test your assistant: ${phoneNumberClean}`);
      setIsDemoButtonDisabled(true);
      setIsDemoLoading(false);

      const timer = setTimeout(async () => {
        setDemoText('Preview your assistant');
        setIsDemoButtonDisabled(false);
        await updateDoc(userRef, {"assistantPhoneNumber": "",}); // Clear the assistant phone number after 10 minutes
      }, 600000); // 10 minutes in milliseconds

      setDemoTimer(timer);
    } catch (error) {
      console.error('Error fetching demo phone number:', error);
      toast.error('Failed to fetch demo phone number. Please try again.');
      setIsDemoLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      if (demoTimer) {
        clearTimeout(demoTimer);
      }
    };
  }, [demoTimer]);

  // ADDED MAY 23
  // Create Stripe Checkout session
  async function handleSubmit() {

    const userRef = doc(db, 'Users', user.uid);
    const userSnap = await getDoc(userRef);
    const existingStripeCustomerID = userSnap.exists() && userSnap.data().stripeCustomerID ? userSnap.data().stripeCustomerID : null;

    try {

      const idToken = await user.getIdToken();

      const response = await fetch(
        'https://stripecheckout-ikpbjdh3wa-uc.a.run.app/create-checkout-session', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${idToken}`
          },
          body: JSON.stringify({
            uid: user.uid,  // Assuming `user` is your authenticated user object
            stripeCustomerID: existingStripeCustomerID  // If available
          })
        }
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const session = await response.json();
      window.location.href = session.url;
    } catch (error) {
      console.error('Error initiating Stripe Checkout:', error);
        toast.error('There was an issue initiating the payment process. Please try again.');
    }
  }
  // ^^^ ADDED MAY 23

  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <div className="max-w-md mx-auto bg-white rounded-lg overflow-hidden md:max-w-lg">
        <div className="md:flex">
          <div className="w-full p-5">
            <h2 className="text-2xl font-semibold text-gray-700 text-center">Assistant Settings</h2>
            <div className="text-sm font-bold text-gray-700 mt-4">Assistant's Name:</div>
            <input
              type="text"
              value={assistantName}
              onChange={(e) => setAssistantName(e.target.value)}
              placeholder="The first name of your AI assistant (the voice is female)."
              className="mt-1 block w-full border-gray-200 rounded-md shadow-sm py-2 px-3 focus:border-indigo-500 focus:ring-indigo-500"
            />
            <div className="text-sm font-bold text-gray-700 mt-4">Answers For:</div>
            <select
              value={answersFor}
              onChange={(e) => setAnswersFor(e.target.value)}
              
              className="mt-1 block w-full border-gray-200 rounded-md shadow-sm py-2 px-3 focus:border-indigo-500 focus:ring-indigo-500"
            >
              <option value="">Select...</option>
              <option value="You">You - “Hello this is *assistants-name*, *your-name*'s assistant.”</option>
              <option value="Company">Your Company - “Hello this is *assistant-name* from *your-company*."</option>
            </select>
            <div className="text-sm font-bold text-gray-700 mt-4">Knowledge:</div>
            <textarea
              value={knowledge}
              onChange={(e) => setKnowledge(e.target.value)}
              placeholder="Here you should put all key information about you/your company (e.g. personal bio, company overview/background, products/services information, Q&A, business hours, location/address etc)."
              className="mt-1 block w-full border-gray-200 rounded-md shadow-sm py-2 px-3 focus:border-indigo-500 focus:ring-indigo-500 h-40"
            ></textarea>
            <button
              onClick={handleDemoClick}
              disabled={isDemoButtonDisabled || isDemoLoading}  // Disable button during loading or when otherwise disabled
              className={`w-full mt-6 ${isDemoButtonDisabled || isDemoLoading ? 'bg-gray-400' : 'bg-green-800 hover:bg-green-900'} text-white font-semibold py-3 px-4 rounded-md`}
            >
              {isDemoLoading ? (
                <div className="flex justify-center items-center">
                  <div className="spinner-border animate-spin inline-block w-4 h-4 border-4 rounded-full" role="status">
                    <span className="visually-hidden">.</span>
                  </div>
                </div>
              ) : (
                demoText
              )}
            </button>
            <button
              onClick={validateAndProceed}
              disabled={isLoading}  // Disable button when loading
              className={`w-full mt-6 ${isLoading ? 'bg-gray-500' : 'bg-green-800 hover:bg-green-900'} text-white font-semibold py-3 px-4 rounded-md`}
            >
              {isLoading ? (
                <div className="flex justify-center items-center">
                  <div className="spinner-border animate-spin inline-block w-4 h-4 border-4 rounded-full" role="status">
                    <span className="visually-hidden">.</span>
                  </div>
                </div>
              ) : (
                'Next'
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssistantSettings;