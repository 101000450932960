// src/pages/TermsOfServicePage.js
import React from 'react';
import { Link } from 'react-router-dom';

const TermsOfServicePage = () => {
    return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
            <div className="bg-white p-8 rounded shadow-md w-3/4 text-left">
                <div className="mb-4">
                    <h1 className="text-4xl text-gray-800 mb-8">Terms of Service</h1>
                    <h2 className="text-xl font-bold text-gray-600 mb-4">Last Updated: April 23, 2024</h2>
                    <p className="text-gray-700 mb-4">
                        Welcome to Ring-a-Ling, a 24/7 AI-powered phone answering service designed to ensure you never miss a call again. Ring-a-Ling helps users by answering calls, providing information, scheduling appointments, and transcribing conversations.
                    </p>

                    <h3 className="text-lg font-bold text-gray-600 mb-2">Definitions</h3>
                    <p className="text-gray-700 mb-4">
                        - **"Service"** refers to Ring-a-Ling's AI-powered phone answering service.
                        - **"User"** or **"You"** refers to the individual or entity using the Service.
                        - **"We"** or **"Us"** refers to Ring-a-Ling and its affiliates.
                        - **"Content"** refers to all information, data, and materials provided or generated by Users through the Service.
                    </p>


                    <h3 className="text-lg font-bold text-gray-600 mb-2">Acceptance of Terms</h3>
                    <p className="text-gray-700 mb-4">
                        By signing up for and using Ring-a-Ling, you agree to be bound by these Terms of Service. If you do not agree to these terms, you must not sign up to or use our services. Continued use of the service after any changes to these terms signifies your acceptance of the new terms.
                    </p>

                    <h3 className="text-lg font-bold text-gray-600 mb-2">User Accounts and Responsibilities</h3>
                    <p className="text-gray-700 mb-4">
                        To use Ring-a-Ling, you must maintain a valid payment method on file. You are responsible for maintaining the confidentiality of your account details and for all activities under your account. You must notify us immediately if you suspect any unauthorized use of your account.
                    </p>


                    <h3 className="text-lg font-bold text-gray-600 mb-2">Service Provision and Usage Terms</h3>
                    <p className="text-gray-700 mb-4">
                        Ring-a-Ling allows you to create a customized AI assistant to manage your calls. The service includes transcriptions, caller identification, and analytics on call duration and timing. Services are provided subject to availability, and you agree to use these services within the bounds of the law and only with a valid payment method.
                    </p>

                    <h3 className="text-lg font-bold text-gray-600 mb-2">Restrictions on Competitive Use</h3>
                    <p className="text-gray-700 mb-4">
                        You are expressly prohibited from using Ring-a-Ling for any benchmarking, competitive analysis, reverse engineering, or development of a similar or competing service. This restriction is intended to prevent the use of Ring-a-Ling to directly compete with our services. 
                    </p>
                    <p className="text-gray-700 mb-4">
                        Additionally, individuals who are equity owners, employees, or otherwise involved with companies that are developing (or plan to develop) competing AI voice assistant technologies are prohibited from using Ring-a-Ling. This is to prevent conflicts of interest and protect our intellectual property. By accepting these terms, you confirm that you are not currently involved with any such competing company and agree not to become involved with any such company during and for a period of 24 months after your use of Ring-a-Ling.
                    </p>

                    <h3 className="text-lg font-bold text-gray-600 mb-2">Content Ownership and Intellectual Property Rights</h3>
                    <p className="text-gray-700 mb-4">
                        You own the data generated from your transcribed calls, which we store securely in an encrypted database. You grant Ring-a-Ling a license to use this data only for providing services to you. We retain all rights to the software, algorithms, and other intellectual property used to provide the services.
                    </p>

                    <h3 className="text-lg font-bold text-gray-600 mb-2">Consent to Record and Transcribe Calls</h3>
                    <p className="text-gray-700 mb-4">
                        By using Ring-a-Ling, you consent to the recording and transcription of your calls. You are responsible for ensuring compliance with local and state laws regarding call recording, including obtaining necessary consents from all parties involved in the calls if required. We make this easy for you with the recorded line notification check box in the settings page.
                    </p>

                    <h3 className="text-lg font-bold text-gray-600 mb-2">Privacy and Data Protection</h3>
                    <p className="text-gray-700 mb-4">
                        We protect your data with strong encryption both in transit and at rest. You have full control over your data, including the right to access, modify, and delete your call transcripts. However, while we strive to protect your information, no security measures are completely infallible.
                    </p>

                    <h3 className="text-lg font-bold text-gray-600 mb-2">Third-Party Services and Content</h3>
                    <p className="text-gray-700 mb-4">
                        Ring-a-Ling integrates with third-party services such as Google. When you choose to use third-party services, their terms and privacy policies apply.
                    </p>

                    <h3 className="text-lg font-bold text-gray-600 mb-2">Prohibited Conduct</h3>
                    <p className="text-gray-700 mb-4">
                        You are prohibited from engaging in, and agree not to engage in, any of the following activities:
                        <br/>
                        - Unauthorized copying, modification, or distribution of any part of our service.
                        <br/>
                        - Reverse engineering, disassembling, decompiling, or otherwise attempting to discover the source code or underlying algorithms of any part of our service, except to the extent that such activities are expressly permitted by applicable law notwithstanding this prohibition.
                        <br/>
                        - Renting, leasing, lending, selling, sublicensing, or otherwise transferring any rights in the service not expressly granted herein.
                        <br/>
                        - Using the service for any illegal or unauthorized purpose, or engaging in any other activity that infringes or violates the intellectual property rights or proprietary rights of Ring-a-Ling or any third party.
                    </p>    

                    <h3 className="text-lg font-bold text-gray-600 mb-2">Termination and Suspension of Accounts</h3>
                    <p className="text-gray-700 mb-4">
                        We may suspend or terminate your account if you violate these Terms or fail to maintain a valid payment method. You may appeal these decisions by contacting us at hello@ringaling.ai.
                    </p>

                    <h3 className="text-lg font-bold text-gray-600 mb-2">Disclaimer of Warranties and Limitation of Liability</h3>
                    <p className="text-gray-700 mb-4">
                        Ring-a-Ling is provided "as is" and "as available" without any warranty of any kind, either express or implied, including but not limited to the implied warranties of merchantability, fitness for a particular purpose, and non-infringement. We are not liable for any direct, indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, resulting from your use of the service. You are solely responsible for ensuring compliance with local and state laws regarding call transcription.
                    </p>

                    <h3 className="text-lg font-bold text-gray-600 mb-2">Dispute Resolution</h3>
                    <p className="text-gray-700 mb-4">
                        For any disputes, please contact us at hello@ringaling.ai. Disputes will be governed under the laws of Delaware, and Delaware will be the jurisdiction for any legal proceedings.
                    </p>

                    <h3 className="text-lg font-bold text-gray-600 mb-2">Accessibility</h3>
                    <p className="text-gray-700 mb-4">
                        We are committed to ensuring that our Service is accessible to everyone, including people with disabilities. We continually improve the user experience for everyone by applying the relevant accessibility standards. If you have trouble accessing any part of our service, please contact us for assistance.
                    </p>

                    <h3 className="text-lg font-bold text-gray-600 mb-2">Modifications to the Terms of Service</h3>
                    <p className="text-gray-700 mb-4">
                        We reserve the right to modify these terms. We will notify you of any changes by email or within the application. Continued use of the service after changes indicates your acceptance of the new terms.
                    </p>

                    <h3 className="text-lg font-bold text-gray-600 mb-2">Contact Information</h3>
                    <p className="text-gray-700 mb-4">
                        For any legal issues or questions about these terms, please contact us at hello@ringaling.ai.
                    </p>
                </div>
                {/* Link back to Home or Dashboard */}
                <Link to="/" className="text-blue-500 hover:text-blue-800 mt-4 inline-block">
                    Return to Home
                </Link>
            </div>
        </div>
    );
};

export default TermsOfServicePage;
