// Input: "+12063506027"
// Output: "(206) 350-6027"
export function formatPhoneNumber(phoneNumberString) {
  const cleaned = phoneNumberString.replace(/\D/g, '');
  // TO DO: global phone numbers would need to change below.
  const match = cleaned.match(/^(1?)(\d{3})(\d{3})(\d{4})$/);
  
  if (match) {
    return `(${match[2]}) ${match[3]}-${match[4]}`;
  }
  
  return null;
}

// Input: "+12063506027"
// Output: "2063506027"
export const formatPhoneNumberWithoutCountryCode = (phoneNumber) => {
  if (phoneNumber.startsWith('+1')) {
    return phoneNumber.slice(2);
  }
  return phoneNumber;
};
  